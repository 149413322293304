<template>
  <div id="app">
    <router-view />
    <!-- <div class="container">
    </div> -->
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
export default {
  setup() {
    // const clinicToggle = ref(false);
    // const showClinicToggle = () => {
    //   clinicToggle.value = !clinicToggle.value;
    // };
    // return { showClinicToggle, clinicToggle };
  },
  computed: {
	
  },
  methods: {
  },
  components: {
  },
};
</script>

<style>
/* #app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
  height: 100vh;
} */
</style>
