import { createWebHistory, createRouter } from "vue-router";
// lazy-loaded
const TileHome = () => import("./components/TileHome.vue")
const AcademyConsult = () => import("./components/AcademyConsult.vue")
const NewtonCourse = () => import("./components/NewtonCourse.vue")
const HighSchool = () => import("./components/HighSchool.vue")

const routes = [
  {
    path: "/",
    component: TileHome,
  },
  {
    path: "/consult",
    name: "consult",
    // lazy-loaded
    component: AcademyConsult,
  },
  {
    path: "/newton_course",
    name: "newton_course",
    // lazy-loaded
    component: NewtonCourse,
  },
  {
    path: "/highschool_intro",
    name: "highschool_intro",
    // lazy-loaded
    component: HighSchool,
  },
];
// const scrollBehavior = function (to, from, savedPosition) {
//   if (to.hash) {
//     return {
//       selector: to.hash,
//       offset: { x: 0, y: -500 }
//     }
//   }
//   return { x: 0, y: 0 };
// }

const router = createRouter({
  history: createWebHistory(),
  // scrollBehavior,
  routes,
});

// router.beforeEach((to, from, next) => {
//   const publicPages = ['/login', '/register', '/home'];
//   const authRequired = !publicPages.includes(to.path);
//   const loggedIn = localStorage.getItem('user');

//   // trying to access a restricted page + not logged in
//   // redirect to login page
//   if (authRequired && !loggedIn) {
//     next('/login');
//   } else {
//     next();
//   }
// });

export default router;