import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { FontAwesomeIcon } from './plugins/font-awesome'
import LoadScript from 'vue-plugin-load-script';
import AppView from "./app";

createApp(App)
  .use(router)
  .use(store)
  .use(AppView)
  .use(LoadScript)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
